import './App.css';
import win from './assets/Win.wav';
import lose from './assets/Lose.wav';
import guessSound from './assets/Guess.wav';
import resetSound from './assets/Reset.wav';
import muteIco from './assets/mute.png';
import unmuteIco from './assets/volume.png';
import { useEffect, useState } from 'react';
import { Guess } from './components/Guess';
import { Modal } from './components/Modal';
import GuessThis from './assets/Guess-This.png';

function App() {
  useEffect(() => {
    try {
      /// (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch {}
  }, []);

  const correct = 10;
  const close = 20;

  const guessAudio = new Audio(guessSound);
  const winAudio = new Audio(win);
  const loseAudio = new Audio(lose);
  const resetAudio = new Audio(resetSound);

  const playAudio = (sound) => {
    guessAudio.pause();
    guessAudio.currentTime = 0;
    winAudio.pause();
    winAudio.currentTime = 0;
    loseAudio.pause();
    loseAudio.currentTime = 0;
    guessAudio.pause();
    resetAudio.currentTime = 0;

    if (!mute) {
      switch (sound) {
        case 'reset':
          resetAudio.play();
          break;
        case 'win':
          winAudio.play();
          break;
        case 'lose':
          loseAudio.play();
          break;
        default:
          guessAudio.play();
      }
    }
  }

  const createGuess = (g) => {
    return {
      submitted: false,
      r: g ? g.r : 0,
      g: g ? g.g : 0,
      b: g ? g.b : 0
    }
  };

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const [previewIndex, setPreviewIndex] = useState(() => 0);
  const [modalShown, setModalShown] = useState(() => false);
  const [mute, setMute] = useState(() => localStorage.getItem('hexle:mute')?.valueOf() === 'true' || false);
  const [guesses, setGuesses] = useState(() => [createGuess()]);
  const [red, setR] = useState(() => randomIntFromInterval(0, 255));
  const [green, setG] = useState(() => randomIntFromInterval(0, 255));
  const [blue, setB] = useState(() => randomIntFromInterval(0, 255));
  const [revealedR, setRevealedR] = useState(() => 0);
  const [revealedG, setRevealedG] = useState(() => 0);
  const [revealedB, setRevealedB] = useState(() => 0);
  const [wins, setWins] = useState(() => parseInt(localStorage.getItem('hexle:wins')?.valueOf() || '0'));
  const [losses, setLosses] = useState(() => parseInt(localStorage.getItem('hexle:losses')?.valueOf() || '0'));
  const [streak, setStreak] = useState(() => parseInt(localStorage.getItem('hexle:winStreak')?.valueOf() || '0'));
  const [streakLongest, setStreakLongest] = useState(() => parseInt(localStorage.getItem('hexle:winStreakLongest')?.valueOf() || '0'));
  const [victory, setVictory] = useState(() => null);
  const [useSliders, setUseSliders] = useState(() => {
    const val = localStorage.getItem('hexle:useSliders');
    if (!val)
      return true;

    return val.valueOf() === 'true' || false
  });
  const [isIos] = useState(() => [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document));

  const colorStyle = {
    backgroundColor: `#${red < 16 ? `0${red.toString(16)}` : red.toString(16)}${green < 16 ? `0${green.toString(16)}` : green.toString(16)}${blue < 16 ? `0${blue.toString(16)}` : blue.toString(16)}`
  }

  const handleGuessChange = (guess, index) => {
    const newGuesses = guesses.map((g, i) => {
      if (index === i) {
        return guess;
      }

      return g;
    });

    setGuesses(newGuesses);
  }

  const guessPasses = (g) => {
    const rPasses = Math.abs(red - g.r) < correct;
    const gPasses = Math.abs(green - g.g) < correct;
    const bPasses = Math.abs(blue - g.b) < correct;
    const rClose = Math.abs(red - g.r) < close;
    const gClose = Math.abs(green - g.g) < close;
    const bClose = Math.abs(blue - g.b) < close;

    /** R */
    if (rPasses)
      setRevealedR(2);
    else if (rClose)
      setRevealedR(1);
    else setRevealedR(0);

    /** G */
    if (gPasses)
      setRevealedG(2);
    else if (gClose)
      setRevealedG(1);
    else setRevealedG(0);

    /** R */
    if (bPasses)
      setRevealedB(2);
    else if (bClose)
      setRevealedB(1);
    else setRevealedB(0);

    return rPasses && gPasses && bPasses;
  }

  const handleGuessSubmit = (guess, index) => {
    playAudio('guess');
    const newGuesses = guesses.map((g, i) => {
      if (index === i) {
        return guess;
      }

      return g;
    });

    if (guessPasses(newGuesses[index])) {
      setGuesses(newGuesses);
      setTimeout(() => playAudio('win'), 500);
      setVictory(true);

      // Set Win and Win Streak
      const w = parseInt(localStorage.getItem('hexle:wins')?.valueOf() || '0');
      const streak = parseInt(localStorage.getItem('hexle:winStreak')?.valueOf() || '0');
      const longestStreak = parseInt(localStorage.getItem('hexle:winStreakLongest')?.valueOf() || '0');
      localStorage.setItem('hexle:wins', w + 1);
      setWins(w + 1);
      localStorage.setItem('hexle:winStreak', streak + 1);
      setStreak(streak + 1);
      if (streak + 1 > longestStreak) {
        localStorage.setItem('hexle:winStreakLongest', streak + 1);
        setStreakLongest(streak + 1);
      }

    } else if (index > 4) { // 6 guesses
      setGuesses(newGuesses);
      setTimeout(() => playAudio('lose'), 500);
      setVictory(false);

      // Set Loss
      const l = parseInt(localStorage.getItem('hexle:losses')?.valueOf() || '0');
      localStorage.setItem('hexle:losses', l + 1);
      setLosses(l + 1);
      localStorage.setItem('hexle:winStreak', 0);
      setStreak(0);
    } else {
      setGuesses([...newGuesses, createGuess(guess)]);
    }

    setTimeout(() => {
      // Scroll to bottom of guess container.
      const objDiv = document.getElementById("right-div");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 10);
  }
  const getColorString = (g) => {
    return `#${g.r < 16 ? `0${g.r.toString(16)}` : g.r.toString(16)}${g.g < 16 ? `0${g.g.toString(16)}` : g.g.toString(16)}${g.b < 16 ? `0${g.b.toString(16)}` : g.b.toString(16)}`;
  }

  const reset = () => {
    playAudio('reset');
    setVictory(null);
    setR(randomIntFromInterval(0, 255));
    setG(randomIntFromInterval(0, 255));
    setB(randomIntFromInterval(0, 255));
    setRevealedR(0);
    setRevealedG(0);
    setRevealedB(0);
    setGuesses([createGuess()]);

    setTimeout(() => {
      if (!document.activeElement?.id.includes('ipt'))
        document.getElementById(`ipt-r`)?.focus();
    }, 500);
  }

  const handleSlidersChange = () => {
    localStorage.setItem('hexle:useSliders', !useSliders);
    setUseSliders(!useSliders);
  };

  useEffect(() => {
    function s(event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }

      if ((event.key === 'R' || event.key === 'r') && victory !== null) {
        if (modalShown) {
          toggleModal();
        }
        reset();
      }

      if (event.key === 'M' || event.key === 'm') {
        toggleMute();
      }

      if ((event.key === 'C' || event.key === 'c') && guesses?.length > 1) {
        if (modalShown) {
          toggleModal();
        } else {
          openPreviewModal(victory !== null ? guesses.length - 1 : guesses.length - 2);
        }
      }

      const num = parseInt(event.key);
      if (num && num < 7) {
        if (victory !== null) {
          if (guesses.length >= num - 1) {
            if (modalShown) {
              toggleModal();
            } else {
              openPreviewModal(num - 1);
            }
          }
        } else {
          if (guesses.length >= num - 1) {
            if (modalShown) {
              toggleModal();
            } else {
              openPreviewModal(num - 1);
            }
          }
        }
      }

      if (event.code === 'Space') {
        handleSlidersChange();
      }
    };

    window.addEventListener('keydown', s);
    return () => window.removeEventListener('keydown', s);
  });

  function toggleModal() {
    setModalShown(!modalShown);
  }

  const openPreviewModal = (i) => {
    setPreviewIndex(i);
    setModalShown(true);
  }

  const toggleMute = () => {
    localStorage.setItem('hexle:mute', !mute);
    setMute(!mute);
  }
  
  const guessCards = guesses.map((g, i) =>
    <Guess className="guess" key={i} g={g} i={i} aR={red} aG={green} aB={blue} victory={victory}
      close={close} correct={correct} colorString={getColorString(g)} useSliders={useSliders}
      handleGuessChange={handleGuessChange} handleGuessSubmit={handleGuessSubmit} openPreview={openPreviewModal}
      revealedR={revealedR} revealedG={revealedG} revealedB={revealedB}>
    </Guess>
  );

  return (
    <div className={`App ${isIos ? 'ios' : ''}`}>
      <div className="floating-corner">
        <img src={GuessThis} style={{display: 'none'}}></img>
        <img src={mute ? muteIco : unmuteIco} className={`mute-icon ${mute ? 'active' : ''}`}
          alt="mute" onClick={() => toggleMute()} />
        <label className="switch">
          <input type="checkbox" checked={useSliders} onChange={handleSlidersChange} />
          <span className="slider"></span>
        </label>
      </div>
      <div className="left" style={colorStyle}>
        <div className="vertical-container">
          { victory === null && <div className="preview-label">Guess this color</div> }
          { victory === true && <div className="preview-label pulser">You win</div> }
          { victory === false && <div className="preview-label pulser">You lose</div> }
          { victory !== null &&
            <>
            <h5 className="my-0 text-white">{`#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`}</h5>
            <div className="result-container">
              <label className="label r">{red}</label>
              <label className="label g mx-1">{green}</label>
              <label className="label b">{blue}</label>
            </div>
            <div className="result-container vertical">
              <label className="label small">Win/Loss: {wins}/{losses}</label>
              <label className="label small">Streak: {streak}</label>
              <label className="label small">Longest: {streakLongest}</label>
            </div>
            <div className="mini-guess btn reset" onClick={reset}>Reset</div>
            </>
          }
        </div>
        <div className="ad-container">
          {/* {ads} */}
        </div>
      </div>
      <div className="right">
        <div className="right-inner" id="right-div">
          { guessCards }
        </div>
      </div>
      <div className="footer">
        <a href="https://www.flaticon.com/free-icons/mute" title="mute icons">Mute icons created by Pixel perfect - Flaticon</a>
      </div>
      { modalShown && <Modal content={(
        <div className="guess-preview">
          <div className="gp gp-left" style={colorStyle}>Guess this color</div>
          <div className="gp gp-right" style={{ backgroundColor: getColorString(guesses[previewIndex])}}>{previewIndex + 1}</div>
        </div>
      )} title={'Compare'} toggle={toggleModal}></Modal> }
    </div>
  );
}

export default App;
